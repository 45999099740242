import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
axios.defaults.baseURL =
  "http://ec2-13-236-193-180.ap-southeast-2.compute.amazonaws.com/";
axios.defaults.headers.common["ngrok-skip-browser-warning"] = true;
root.render(
  <>
    <App />
    <ToastContainer position="top-center" theme="colored" />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
