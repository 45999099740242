import React from "react";

const PdfPage = () => {
  const pdfUrl = process.env.PUBLIC_URL + "/assets/DC PROFILE.pdf";

  return (
    <div className="w-full h-screen overflow-hidden">
      <iframe
        src={pdfUrl}
        title="PDF Viewer"
        className="w-full h-full max-w-full object-contain"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default PdfPage;
