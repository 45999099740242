import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
function ScreenProducts() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);
  const [projectorProducts, setProjectorProducts] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(0);
  const [updatedProductPrice, setUpdatedProductPrice] = useState(0);
  const [productAmount, setProductAmount] = useState();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [particularProductData, setParticularProductData] = useState({});
  const [updateId, setUpdateId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [formData, setFormData] = useState({
    code: "",
    MRP: "",
  });
  const { code, MRP } = formData;
  useEffect(() => {
    axios
      .get("api/v1/products")
      .then((res) => {
        console.log(res.data.products);
        const allProducts = res.data.products;
        const wooferProducts = allProducts.filter(
          (product) => product.category === "Screen"
        );
        setProjectorProducts(wooferProducts);
      })
      .catch((err) => {
        console.error(err);
        toast.error("product not found");
      });
  }, []);

  const handleViewMore = (product_id) => {
    setDeleteId(product_id);
    console.log(product_id);
    setIsPopupOpen(true);
    axios
      .get(
        `http://ec2-15-206-116-92.ap-south-1.compute.amazonaws.com/api/v1/products/${product_id}`
      )
      .then((res) => {
        console.log(res.data);
        setParticularProductData(res.data.product);
      })
      .catch((err) => {
        console.error(err);
        toast.error("product not found");
      });
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOpenSecondPopup = (price) => {
    setProductAmount(price);
    setIsSecondPopupOpen(true);
  };
  const handleCloseSecondPopup = () => {
    setIsSecondPopupOpen(false);
  };

  // Function to handle the checkbox selection
  const handleDiscountSelection = (discount) => {
    setSelectedDiscount(discount);
    const updatedPrice = productAmount - (productAmount * discount) / 100;
    setUpdatedProductPrice(updatedPrice);
  };

  // Function to handle adding the product to quotation with or without discount
  const handleAddToQuotation = () => {
    // Add your logic here to handle adding the product to the quotation with or without discount
    // For example, you can pass the selected product and selectedDiscount to your quotation handling function.
    // Then, close the second popup.
    setIsSecondPopupOpen(false);
  };
  const handleDelete = (id) => {
    // Display a confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmDelete) {
      console.log(id);
      setIsLoading(true);
      axios
        .delete(`api/v1/products/${id}`)
        .then((res) => {
          console.log(res);
          setIsPopupOpen(false);
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false); // Set loading back to false regardless of success or error
        });
    }
  };
  const handleUpdateButton = (id) => {
    setIsFormOpen(true);
    setUpdateId(id);
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be sent in the PUT request
    const dataToUpdate = {
      item_code: formData.code,
      price: formData.MRP,
      // Add other properties to update here if needed
    };

    // Replace "apiEndpoint" with the actual API endpoint where you want to perform the PUT request.
    axios
      .put(`api/v1/products/${updateId}/`, dataToUpdate)
      .then((response) => {
        // Handle the response, e.g., display success message, reset the form, etc.
        console.log("Data updated successfully:", response.data);
        setFormData({
          code: "",
          MRP: "",
        });
        window.location.reload();
        toast();
      })
      .catch((error) => {
        // Handle errors
        console.error("Error updating data:", error);
      });
  };

  return (
    <div className="mt-5">
      <motion.div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
        {projectorProducts.map((product) => (
          <div key={product.id}>
            <motion.div
              className="flex flex-col items-center shadow-md rounded-lg p-4"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              whileHover={{
                scale: 1.02,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              transition={{ duration: 0.3 }}
            >
              <motion.img
                src={product.image}
                alt={product.image}
                className="w-32 h-auto mb-4"
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
              <motion.h2
                className="text-xl font-bold"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                {product.item_code}
              </motion.h2>
              <motion.div
                className="mt-4 flex space-x-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                {/* <motion.button
                  className="flex-1 px-4 py-2 rounded-lg font-bold text-white bg-gradient-to-r from-gray-400 to-gray-500 hover:from-gray-500 hover:to-gray-400 focus:outline-none shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => handleOpenSecondPopup(product.price)}
                >
                  ADD
                </motion.button> */}
                <motion.button
                  className="flex-1 px-4 py-2 rounded-lg font-bold text-white bg-gray-500 hover:bg-gray-600 focus:outline-none shadow-lg"
                  whileHover={{ scale: 1.05, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => handleViewMore(product.id)}
                >
                  VIEW
                </motion.button>
              </motion.div>
            </motion.div>

            {/* {isSecondPopupOpen && (
              <motion.div
                className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-gray-900"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.div
                  className="bg-white w-[400px] rounded-lg p-8 shadow-lg"
                  initial={{ scale: 0.8 }}
                  exit={{ scale: 0.8 }}
                >
                  <button
                    onClick={handleCloseSecondPopup}
                    className="absolute top-2 right-4 text-2xl font-extrabold text-gray-600 focus:outline-none"
                  >
                    x
                  </button>

                  <h2 className="text-2xl font-bold mb-4">
                    Choose Additional Discount
                  </h2>

                  <div className="grid grid-cols-2 gap-4">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedDiscount === 0}
                        onChange={() => handleDiscountSelection(0)}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span>0% Discount</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedDiscount === 10}
                        onChange={() => handleDiscountSelection(10)}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span>10% Discount</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedDiscount === 20}
                        onChange={() => handleDiscountSelection(20)}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span>20% Discount</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedDiscount === 30}
                        onChange={() => handleDiscountSelection(30)}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span>30% Discount</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedDiscount === 40}
                        onChange={() => handleDiscountSelection(40)}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span>40% Discount</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedDiscount === 50}
                        onChange={() => handleDiscountSelection(50)}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span>50% Discount</span>
                    </label>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => handleAddToQuotation()}
                      className="flex-1 px-4 py-2 bg-gray-500 rounded-lg text-white font-bold focus:outline-none"
                    >
                      ADD TO QUOTATION
                    </button>
                  </div>

                  {selectedDiscount >= 0 && (
                    <p className="text-xl font-bold mt-4">
                      Discounted Price: {updatedProductPrice}
                    </p>
                  )}
                </motion.div>
              </motion.div>
            )} */}
          </div>
        ))}
      </motion.div>
      {isPopupOpen && (
        <motion.div
          key={particularProductData.id}
          className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-gray-900"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white w-[400px] rounded-lg p-8 shadow-lg"
            initial={{ scale: 0.8 }}
            // animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
          >
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-600 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="flex items-center justify-center mb-4">
              <img
                src={particularProductData.image}
                alt={particularProductData.image}
                className="w-48 h-auto rounded-lg"
              />
            </div>

            <h2 className="text-2xl font-bold mb-2">
              {particularProductData.item_code}
            </h2>

            <div className="flex justify-between mb-4">
              <div>
                <p className="font-bold">Code:</p>
                <p>{particularProductData.item_code}</p>
              </div>
              <div>
                <p className="font-bold">Make:</p>
                <p>{particularProductData.make}</p>
              </div>
            </div>
            <div>
              <p className="font-bold">Description: </p>
              <p>{particularProductData.description}</p>
            </div>

            <div className="flex justify-between mb-4">
              <div>
                <p className="font-bold">Brand Name:</p>
                <p>{particularProductData.brand_name}</p>
              </div>
              <div>
                <p className="font-bold">MRP:</p>
                <p className="line-through">Rs {particularProductData.price}</p>
                <p>Rs {particularProductData.discounted_price}</p>
              </div>
            </div>

            {isFormOpen && (
              <div>
                <h1 className="text-center font-bold">Update </h1>
                <form className="flex justify-between" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="code" // Set the name attribute to match the key in formData state
                    defaultValue={code}
                    onChange={handleChange}
                    className="m-2 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="code"
                  />
                  <input
                    type="text"
                    name="MRP" // Set the name attribute to match the key in formData state
                    defaultValue={MRP}
                    onChange={handleChange}
                    className="m-2 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="MRP"
                  />
                  <button type="submit">UPDATE</button>
                </form>
              </div>
            )}
            <div className="flex justify-between mt-4">
              {isLoading ? (
                <button className="w-full px-2 py-2 m-1 bg-gray-500 rounded-lg text-white font-bold focus:outline-none">
                  Loading
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleUpdateButton(particularProductData.id);
                  }}
                  className="w-full px-2 py-2 m-1 bg-gray-500 rounded-lg text-white font-bold focus:outline-none"
                >
                  UPDATE
                </button>
              )}
              {isLoading ? (
                <button className="w-full px-2 py-2 m-1 bg-gray-500 rounded-lg text-white font-bold focus:outline-none">
                  Loading
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleDelete(particularProductData.id);
                  }}
                  className="w-full px-2 py-2 m-1 bg-gray-500 rounded-lg text-white font-bold focus:outline-none"
                >
                  DELETE
                </button>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export default ScreenProducts;
