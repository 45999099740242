import React from "react";
import Downloadpdf from "./components/Downloadpdf";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="bg-[#A91332] font-sans">
        <div className="container px-6 py-12 mx-auto">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
            <div className="sm:col-span-2">
              <h1 className="max-w-lg text-xl font-bold tracking-tight text-red-100 xl:text-2xl dark:text-white">
                DYNAMO AND CO
              </h1>
              <h1 className="max-w-lg font-semibold tracking-tight text-red-100 text-lg">
                ADDRESS:
                <span className=" font-normal ml-1">
                  D 3GR Casa Rio Gold Kalyan MURIEL Shill Road Kalyan Thane
                  Maharashtra 421204
                </span>
              </h1>
              <h1 className="max-w-lg font-semibold tracking-tight text-red-100 text-lg">
                CONTACT NO:
                <span className=" font-normal ml-1">
                  9022187166 / 9167543978
                </span>
              </h1>
              <h1 className="max-w-lg font-semibold tracking-tight text-red-100 text-lg">
                GST NO:<span className=" font-normal ml-1">BZUPK3935J1O</span>
              </h1>
            </div>
            <div>
              <p className="font-semibold text-red-100 dark:text-white">
                Quick Link
              </p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <Link
                  to="/"
                  className="text-red-50 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:cursor-pointer hover:text-white"
                >
                  Home
                </Link>
                <Link
                  to="/Services"
                  className="text-red-50 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:cursor-pointer hover:text-white"
                >
                  Services
                </Link>
                <Link
                  to="/contact"
                  className="text-red-50 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:cursor-pointer hover:text-white"
                >
                  Contact
                </Link>
              </div>
            </div>
            <div>
              <Downloadpdf />
            </div>
          </div>
        </div>
        <hr className="my-6" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-red-50 font-semibold py-1">
              {" "}
              <p className="text-base font-sans ">
                All Rights Reserved By Dynamo and co
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
