import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "../Navbar";
import Footer from "../Footer";
function CustomerScreen() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [projectorProducts, setProjectorProducts] = useState([]);

  const [productAmount, setProductAmount] = useState();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [particularProductData, setParticularProductData] = useState({});
  const [formData, setFormData] = useState({
    code: "",
    MRP: "",
  });
  const { code, MRP } = formData;
  useEffect(() => {
    axios
      .get("api/v1/products")
      .then((res) => {
        // console.log(res.data.products);
        const allProducts = res.data.products;
        const wooferProducts = allProducts.filter(
          (product) => product.category === "Screen"
        );
        setProjectorProducts(wooferProducts);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  }, []);

  const openProduct = (projectId) => {
    window.open(`/product/${projectId}`);
  };

  return (
    <div>
      <Navbar />
      <div className="mt-10 ml-5">
        <h1 className="text-4xl font-mono font-extrabold text-[#A91332]">
          SCREENS
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5 mb-10">
        {projectorProducts &&
          projectorProducts.map((data, index) => (
            <div
              className="flex flex-col items-center cursor-pointer justify-center w-full max-w-lg mx-auto bg-red-50 p-5 rounded-3xl hover:shadow-xl duration-300 ease-in-out"
              key={index}
              onClick={() => openProduct(data.id)}
            >
              <img
                className="object-cover w-full rounded-md h-72 xl:h-80"
                src={data.image}
                alt="woofer"
              />
              <h4 className="mt-2 text-lg font-medium text-gray-700 dark:text-gray-200">
                {data.brand_name} {data.item_code}
              </h4>
              <p className="text-blue-500 text-lg">
                ₹{data.discounted_price}{" "}
                <span className="ml-2 text-gray-400 text-base line-through">
                  ₹{data.price}
                </span>
              </p>
            </div>
          ))}
      </div>
      <Footer />
    </div>
  );
}

export default CustomerScreen;
