import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";

function ViewProduct() {
  const [detail, setDetail] = useState("");
  const { productId } = useParams();
  const fetchDetail = () => {
    axios
      .get(`api/v1/products/${productId}`)
      .then((response) => {
        setDetail(response.data.product);
      })
      .catch((error) => console.log(error));
  };
  console.log(detail);
  useEffect(() => {
    fetchDetail();
  }, []);

  const renderProductDetails = () => {
    switch (detail.category) {
      case "Speaker":
        return (
          <div>
            <h1 className="text-xl text-gray-600 font-semibold mt-7">
              Details
            </h1>
            <div>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                QUANTITY IN: {detail.details.quantity_in}
              </p>
            </div>
          </div>
        );
      case "Amplifier":
        return (
          <div>
            <h1 className="text-xl text-gray-600 font-semibold mt-7">
              Details
            </h1>
            <div>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                AVR: {detail.details.avr}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                CHANNEL: {detail.details.channel}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                CONNECTIVITY IN: {detail.details.connectivity_in}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                CONNECTIVITY OUT: {detail.details.connectivity_out}
              </p>
            </div>
          </div>
        );
      case "Screen":
        return (
          <div>
            <h1 className="text-xl text-gray-600 font-semibold mt-7">
              Details
            </h1>
            <div>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                SIZE: {detail.details.size}
              </p>
            </div>
          </div>
        );
      case "Acoustics":
        return (
          <div>
            <div>
              <h1 className="text-xl text-gray-600 font-semibold mt-7">
                Details
              </h1>
              <div>
                <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                  SIZE: {detail.details.size}
                </p>
              </div>
            </div>
          </div>
        );
      case "Woofer":
        return (
          <div>
            <h1 className="text-xl text-gray-600 font-semibold mt-7">
              Details
            </h1>
            <div>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                RMS: {detail.details.rms}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                COIL: {detail.details.coil}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                DRIVER SIZE: {detail.details.driver_size}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                FIRING: {detail.details.firing}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                IMPEDANCE: {detail.details.impedence}
              </p>
            </div>
          </div>
        );
      case "Projector":
        return (
          <div>
            <h1 className="text-xl text-gray-600 font-semibold mt-7">
              Details
            </h1>
            <div>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                WIFI: {detail.details.wifi}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                BATTERY: {detail.details.battery}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                BRIGHTNESS: {detail.details.brightness}
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600 dark:text-gray-300">
                CHROME_CAST: {detail.details.chrome_cast}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                COLOR GAMUT: {detail.details.color_gamut}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                CONNECTIVITY: {detail.details.connectivity}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                CONTRAST RATIO: {detail.details.contrast_ratio}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                FEATURES: {detail.details.interactive_features}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                KEYSTONE CORRECTION: {detail.details.keystone_correction}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                LAMP LIFE: {detail.details.lamp_life} YEARS
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                RESOLUTIONS: {detail.details.resolutions}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                SMART TV: {detail.details.smart_tv}
              </p>
              <p className="md:w-96 text-base leading-normal text-gray-600 dark:text-gray-300 mt-4">
                WEIGHT: {detail.details.weight} Kg
              </p>
            </div>
          </div>
        );
      default:
        return <div>Unsupported product category</div>;
    }
  };

  return (
    <div>
      <Navbar />

      <section className="text-gray-700 body-font overflow-hidden ">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            <img
              alt="ecommerce"
              className="lg:w-1/2 w-full h-96 object-cover object-center rounded border border-gray-200"
              src={detail.image}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="text-base title-font text-gray-500 tracking-widest">
                {detail.brand_name}
              </h2>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                {detail.item_code}
              </h1>

              <p className="leading-relaxed">{detail.description}</p>

              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  ₹{detail.discounted_price}
                  <span className="tracking-normal text-gray-600 text-sm ml-2 line-through">
                    ₹{detail.price}
                  </span>
                  <span className="ml-10 text-sm text-gray-800">
                    Discount:
                    <span className="text-green-500">{detail.discount}%</span>
                  </span>
                </span>
              </div>
              {renderProductDetails()}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ViewProduct;
