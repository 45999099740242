import React from "react";
// import pdfUrl from "../assets/DC PROFILE.pdf";

function Downloadpdf() {
  const pdfUrl = process.env.PUBLIC_URL + "/assets/DC PROFILE.pdf";
  return (
    <div>
      <div className="mt-4 ">
        <a
          href={pdfUrl}
          download="your_pdf_file.pdf"
          className="bg-black duration-300 ease-out text-lg  text-white p-2 rounded-md hover:text-black hover:bg-white font-bold font-mono tracking-widest border-none outline-none"
        >
          DOWNLOAD BROCHURE
        </a>
      </div>
    </div>
  );
}

export default Downloadpdf;
