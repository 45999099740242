import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import home from "../images/home theater.png";
import acous from "../images/acoustic-demi.png";
import auto from "../images/auto.png";
import audio from "../images/proaudio.png";

function Service() {
  const scrollToHomeTheatre = () => {
    const homeTheatreSection = document.getElementById("homeTheatre");
    if (homeTheatreSection) {
      homeTheatreSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scrollToAcoustics = () => {
    const acousticSection = document.getElementById("acoustics");
    if (acousticSection) {
      acousticSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scrollToHomeAuto = () => {
    const homeAutoSection = document.getElementById("homeAuto");
    if (homeAutoSection) {
      homeAutoSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scrollToProAudio = () => {
    const proAudioSection = document.getElementById("proAudio");
    if (proAudioSection) {
      proAudioSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="m-5">
        <h1 className="text-4xl font-mono font-extrabold">
          OUR
          <span className="ml-2 text-[#A91332]">
            SERVIC
            <span className="underline">ES</span>
          </span>
        </h1>
      </div>
      <div className=" grid items-center  grid-cols-2 gap-1 mx-auto mt-12 md:mt-5 md:grid-cols-4  mb-10">
        <div className=" h-12 flex  items-center justify-center">
          <h1
            onClick={scrollToHomeTheatre}
            className="font-semibold text-lg cursor-pointer relative group"
          >
            HOME THEATRE
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1
            onClick={scrollToAcoustics}
            className="font-semibold text-lg cursor-pointer relative group"
          >
            ACOUSTICS
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1
            onClick={scrollToHomeAuto}
            className="font-semibold text-lg cursor-pointer relative group"
          >
            AUTOMATION
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1
            className="font-semibold text-lg cursor-pointer relative group"
            onClick={scrollToProAudio}
          >
            PRO AUDIO
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            IP NETOWRK
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            ACCESS CONTROL
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            SYSTEM INTEGRATION
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            BGM & PA SYSTEM
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            DISCO |PUB | HOTELS
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            SIGNAGES{" "}
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            CARPET & CHAIRS
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            FIRE ALARM SYSTEMS
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
        <div className=" h-12 flex  items-center justify-center">
          <h1 className="font-semibold text-lg cursor-pointer relative group">
            PLUMBING{" "}
            <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
          </h1>
        </div>
      </div>

      <div className="w-full my-10 z-50 sticky   px-6 ">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div
            className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row"
            id="homeTheatre"
          >
            <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <h2 className="font-sans text-3xl font-bold tracking-tight  sm:text-4xl sm:leading-none max-w-lg mb-6 text-[#A91332] mt-20">
                  HOME THEATRE
                </h2>
                <p className=" text-xl font-semibold md:text-lg">
                  10 BENEFITS TO HAVING A DEDICATED HOME THEATRE IN YOUR HOME
                </p>
                <p className=" text-lg md:text-lg">
                  These days, staying at home and entertaining your family is
                  becoming more and more desirable and to have a home theatre is
                  just the way to do that! We LOVE designing and installing home
                  theatres, so here are ten benefits to have own dedicated home
                  theater
                </p>
                <li className=" text-lg  md:text-base">
                  You get the same movie going experience, without the hassle
                </li>
                <li className=" text-lg  md:text-base">
                  You can take your video games to a new level
                </li>
                <li className=" text-lg  md:text-base">
                  You have front row tickets to any sporting event
                </li>
                <li className=" text-lg  md:text-base">
                  You have complete reign over the remote control
                </li>
                <li className=" text-lg  md:text-base">
                  Home entertainment isn't just movies anymore. Netflix, Hulu
                  and other streaming. services
                </li>
                <li className=" text-lg  md:text-base">
                  Sound and acoustics are how they should be
                </li>
                <li className=" text-lg  md:text-base">
                  You can choose your own furniture
                </li>
                <li className=" text-lg  md:text-base">
                  You can use (almost) any room for a home theater
                </li>
                <li className=" text-lg  md:text-base">
                  You can have fun with technology
                </li>
                <li className=" text-lg  md:text-base">
                  You increase the value of your home.
                </li>
              </div>
            </div>
            <img
              alt="logo"
              src={home}
              className="w-1/2 rounded-3xl shadow-lg"
            />
          </div>
        </div>

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div
            className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row"
            id="acoustics"
          >
            <img
              alt="logo"
              src={acous}
              className="w-1/2 rounded-3xl shadow-lg"
            />
            <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <h2 className="font-sans text-3xl sm:mt-0 mt-6 font-bold tracking-tight  sm:text-4xl sm:leading-none max-w-lg mb-6  text-[#A91332]">
                  ACOUSTICS
                </h2>
                <p className=" text-xl font-semibold md:text-lg">
                  WE PROVIDE DIFFERENT TYPES OF ACCOUSTIC SOLUTIONS
                </p>
                <p className=" text-lg md:text-lg">
                  How do you get great sound from youraudio system? There's a
                  3-part formula Good gear, Proper speaker placement, Acoustic
                  treatment of your listening room
                </p>
                <p className=" text-lg  md:text-base">
                  The sound you hear in any room is a combination of direct and
                  reflected sound. Direct sound travels straight from your
                  speakers to your ears. Reflected pund bounces off your walls,
                  floor, ceilingand furniture before it reaches your ears.
                  Reflected sound can add a pleasant spaciousness to your sound.
                  But it can also distort sound by making certain. notes sound
                  louder while canceling out others. Problems caused by
                  reflected sound can Include: Midrange and treble that's too
                  bright and harsh Bass notes that are boomy, with a muddy
                  "one-note" quality that drowns out deep bass A vague or
                  smeared "soundstage" The soundstage is the three-dimensional
                  effect that makes you feel like you're listening to a live
                  performance. You hear the placement of the instruments from
                  one side of the virtual stage to the other and from front to
                  back, tool. When reflected sounds arrive at your ears at
                  different times, the soundstage tends to collapse. The music
                  doesn't move you nearly as much as it could
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div
            className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row"
            id="homeAuto"
          >
            <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <h2 className="font-sans text-3xl font-bold tracking-tight  sm:text-4xl sm:leading-none max-w-lg mb-6  text-[#A91332]   ">
                  HOME AUTOMATION
                </h2>
                <p className=" text-xl font-semibold md:text-lg">
                  SMART HOME AUTOMATION, ADDS CONVENIENCE, COMFORT, AND PEACE OF
                  MIND
                </p>
                <p className=" text-lg md:text-lg">
                  WHAT IS SMART HOME AUTOMATION?
                </p>
                <p className=" text-lg  md:text-base">
                  Smart home technology generally refers to any suite of
                  devices, appliances, or systems that connect into a common
                  network that can be independently and remotely controlled.
                  When your home technology works together in one system, it can
                  also be referred more loosely as a "connected home". For
                  example, your home's thermostat, lights, audio spears, TVs,
                  security cameras, locks, appliances, and more are all
                  connected into a common system, which can be controlled from
                  your smart phone or through a mobile touch screen device Smart
                  home automation allows you to tap Into high-tech functionality
                  and luxury that wasn't possible in the past. As technology
                  development continues to expand, so will the possibilities for
                  consumer home automation to make life easier and more
                  enjoyable
                </p>
              </div>
            </div>
            <img
              alt="logo"
              src={auto}
              className="w-1/2 rounded-3xl shadow-lg"
            />
          </div>
        </div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div
            className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row"
            id="proAudio"
          >
            <img
              alt="logo"
              src={audio}
              className="w-1/2 rounded-3xl shadow-lg"
            />

            <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <h2 className="font-sans text-3xl sm:mt-0 mt-6 font-bold tracking-tight  sm:text-4xl sm:leading-none max-w-lg mb-6  text-[#A91332]">
                  PRO AUDIO SOLUTIONS
                </h2>
                <p className=" text-xl font-semibold md:text-lg">
                  WE SUPPLY SOUND NOT EQUIPMENT
                </p>
                <li className=" text-lg  md:text-base">Live Sound</li>
                <li className=" text-lg  md:text-base">Installed Sound</li>
                <li className=" text-lg  md:text-base">Auditorium Sound</li>
                <li className=" text-lg  md:text-base">Theatre Sound</li>
                <li className=" text-lg  md:text-base">Conference Room</li>
                <li className=" text-lg  md:text-base">Paging System</li>
                <li className=" text-lg  md:text-base">PA System</li>
                <li className=" text-lg  md:text-base">Intercom</li>
                <li className=" text-lg  md:text-base">Classroom Solution</li>
                <li className=" text-lg  md:text-base">Retail Complex</li>
                <li className=" text-lg  md:text-base">Mall</li>
                <li className=" text-lg  md:text-base">Cinema Hall</li>
                <li className=" text-lg  md:text-base">Smart City</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Service;
