import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Service from "./customer/Service";
import Products from "./components/Products";
import Bills from "./components/Bills";
import Quotations from "./components/Quotations";
import ListedProducts from "./components/ListedProducts";
import QuotationDetails from "./components/QuotationDetails";
import QuotationsProduct from "./components/QuotationsProduct";
import CustomerProducts from "./customer/CustomerProducts";
import QuotationHistory from "./components/QuotationHistory";
import CustomerSpeaker from "./customer/CustomerSpeaker";
import CustomerProjector from "./customer/CustomerProjector";
import CustomerWoofer from "./customer/CustomerWoofer";
import CustomerAmplifier from "./customer/CustomerAmplifier";
import CustomerScreen from "./customer/CustomerScreen";
import CustomerAcoustics from "./customer/CustomerAcoustics";
import PdfPage from "./components/PdfPage";
import ViewProduct from "./customer/ViewProduct";
import Contact from "./customer/Contact";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<CustomerProducts />} />
          <Route path="/Admin" element={<Products />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Quotations" element={<Quotations />} />
          <Route path="/Bills" element={<Bills />} />
          <Route path="/ListedProducts" element={<ListedProducts />} />
          <Route path="/QuotationDetails" element={<QuotationDetails />} />
          <Route path="/QuotationsProduct" element={<QuotationsProduct />} />
          <Route path="/QuotationHistory" element={<QuotationHistory />} />
          <Route path="/CustomerSpeaker" element={<CustomerSpeaker />} />
          <Route path="/CustomerProjector" element={<CustomerProjector />} />
          <Route path="/CustomerWoofer" element={<CustomerWoofer />} />
          <Route path="/CustomerAmplifier" element={<CustomerAmplifier />} />
          <Route path="/CustomerScreen" element={<CustomerScreen />} />
          <Route path="/CustomerAcoustics" element={<CustomerAcoustics />} />
          <Route path="/download_brochure" element={<PdfPage />} />
          <Route path="/Services" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/product/:productId" element={<ViewProduct />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
