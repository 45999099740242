import React, { useEffect, useState } from "react";
import AcousticsForm from "../components/AcousticsForm";
import ScreenForm from "../components/ScreenForm";
import { useNavigate } from "react-router-dom";
import CustomerSpeaker from "./CustomerSpeaker";
import CustomerProjector from "./CustomerProjector";
import CustomerWoofer from "./CustomerWoofer";
import CustomerAmplifier from "./CustomerAmplifier";
import CustomerScreen from "./CustomerScreen";
import CustomerAcoustics from "./CustomerAcoustics";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import speaker from "../images/dynamo-speaker.jpg";
import projector from "../images/dynamo-projector.jpg";
import woofer from "../images/dynamo-woofer.jpg";
import amplifier from "../images/Amplifier.jpg";
import screen from "../images/Screen.jpg";
import acoustic from "../images/Acoustic.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import dynamo from "../images/DYNAMO.png";
import dupli from "../images/hamza-cherif-elias--SVW5wqvaHI-unsplash.jpg";
import animal from "../images/_e7caa69f-345b-466d-ba9e-7406d68b8c97-removebg-preview.png";
import roar from "../images/Sound that surpasses roar, echoes power.png";
import images from "./images";

import axios from "axios";

function CustomerProducts() {
  const [showProjectorForm, setShowProjectorForm] = useState(false);
  const [showWooferForm, setShowWooferForm] = useState(false);
  const [showAmplifierForm, setShowAmplifierForm] = useState(false);
  const [showSpeakerForm, setShowSpeakerForm] = useState(false);
  const [showScreenForm, setShowScreenForm] = useState(false);
  const [showAcousticsForm, setShowAcousticsForm] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [productData, setProductData] = useState("");
  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const navigate = useNavigate();

  const handleProjectorForm = () => {
    navigate("/CustomerProjector");
    // setShowProjectorForm(!showProjectorForm);
    // setShowWooferForm(false);
    // setShowAmplifierForm(false);
    // setShowSpeakerForm(false);
    // setShowScreenForm(false);
    // setShowAcousticsForm(false);
    // setIsActive(!isActive);
    // setIsActive1(false);
    // setIsActive2(false);
    // setIsActive3(false);
    // setIsActive4(false);
    // setIsActive5(false);
  };
  const handleWooferForm = () => {
    navigate("/CustomerWoofer");
    // setShowWooferForm(!showWooferForm);
    // setShowProjectorForm(false);
    // setShowAmplifierForm(false);
    // setShowSpeakerForm(false);
    // setShowScreenForm(false);
    // setShowAcousticsForm(false);
    // setIsActive(false);
    // setIsActive1(!isActive1);
    // setIsActive2(false);
    // setIsActive3(false);
    // setIsActive4(false);
    // setIsActive5(false);
  };
  const handleAmplifiresForm = () => {
    navigate("/CustomerAmplifier");
    // setShowAmplifierForm(!showAmplifierForm);
    // setShowProjectorForm(false);
    // setShowWooferForm(false);
    // setShowSpeakerForm(false);
    // setShowScreenForm(false);
    // setShowAcousticsForm(false);
    // setIsActive(false);
    // setIsActive1(false);
    // setIsActive2(!isActive2);
    // setIsActive3(false);
    // setIsActive4(false);
    // setIsActive5(false);
  };
  const handleSpeakersForm = () => {
    navigate("/CustomerSpeaker");
    // setShowSpeakerForm(!showSpeakerForm);
    // setShowProjectorForm(false);
    // setShowWooferForm(false);
    // setShowAmplifierForm(false);
    // setShowScreenForm(false);
    // setShowAcousticsForm(false);
    // setIsActive(false);
    // setIsActive1(false);
    // setIsActive2(false);
    // setIsActive3(!isActive3);
    // setIsActive4(false);
    // setIsActive5(false);
  };
  const handleScreenForm = () => {
    navigate("/CustomerScreen");
    // setShowProjectorForm(false);
    // setShowWooferForm(false);
    // setShowAmplifierForm(false);
    // setShowScreenForm(!showScreenForm);
    // setShowAcousticsForm(false);
    // setShowSpeakerForm(false);
    // setIsActive(false);
    // setIsActive1(false);
    // setIsActive2(false);
    // setIsActive3(false);
    // setIsActive4(!isActive4);
    // setIsActive5(false);
  };
  const handleAcousticsForm = () => {
    navigate("/CustomerAcoustics");
    // setShowProjectorForm(false);
    // setShowWooferForm(false);
    // setShowAmplifierForm(false);
    // setShowScreenForm(false);
    // setShowAcousticsForm(!showAcousticsForm);
    // setShowSpeakerForm(false);
    // setIsActive(false);
    // setIsActive1(false);
    // setIsActive2(false);
    // setIsActive3(false);
    // setIsActive4(false);
    // setIsActive5(!isActive5);
  };

  const clientName = [
    "SULA VINES",
    "THE FERN-RAKABI,IGATPURI",
    "VENKATESH NAGAR,IGATPURI",
    "ARTILLERY CENTRE",
    "AKURDI AUDITORIUM",
    "SAHYADRI FARMS",
    "ACHARYA ATTRAY AUDITORIUM",
    "SHREE SAI RESORT",
    "HOTEL ASHWIN",
    "RIDHAN BUILCON",
    "DIVAN STUDIO",
    "CITY CENTRE MALL",
    "P&P BUILDER",
    "KATHMANDU UNIVERSITY",
    "MAHATMAJI FOODS",
    "SADHBHAV GROUP",
  ];

  const fetchProducts = () => {
    axios
      .get(`api/v1/products`)
      .then((response) => {
        setProductData(response.data.products);
        console.log(response.data.products);
      })
      .catch((error) => console.log("Error fetching product Data: ", error));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const openProduct = (projectId) => {
    window.open(`/product/${projectId}`);
  };

  return (
    <div className="bg-[]">
      <Navbar />
      <AliceCarousel
        autoPlay={true} 
        infinite={true}
        animationType="fadeout"
        autoPlayInterval={4000}
        disableButtonsControls
      >
        <img src={roar} alt="image" className="h-[650px] w-full"></img>
        <img
          src="https://images.unsplash.com/photo-1635788798247-92a15f830a3b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="image"
          className="h-[600px] w-full"
        ></img>
        <img
          src="https://images.unsplash.com/photo-1436473849883-bb3464c23e93?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="image"
          className="h-[600px] w-full"
        ></img>
      </AliceCarousel>
      <section className="text-gray-600 body-font ">
        <div className="flex flex-col  m-auto p-auto">
          <h1 className="flex py-5 m-4 font-bold text-4xl text-gray-800 font-mono ">
            EXPLORE
            <span className="text-[#A91332] ml-3">
              {" "}
              CATEGORI<span className="underline ">ES</span>
            </span>
          </h1>
          <div className="flex overflow-x-scroll pb-10 no-scrollbar">
            <div className="flex flex-nowrap ml-10  ">
              <div
                onClick={handleSpeakersForm}
                className="inline-block px-3 cursor-pointer"
              >
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md  bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    alt="category"
                    className="hover:scale-105 duration-300 ease-linear"
                    src={speaker}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-semibold text-lg">SPEAKER</h1>
                </div>
              </div>
              <div
                onClick={handleProjectorForm}
                className="inline-block px-3 cursor-pointer"
              >
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    alt="category"
                    className="hover:scale-105 duration-300 ease-linear"
                    src={projector}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-semibold text-lg">PROJECTOR</h1>
                </div>
              </div>
              <div
                onClick={handleWooferForm}
                className="inline-block px-3 cursor-pointer"
              >
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    alt="category"
                    className="hover:scale-105 duration-300 ease-linear"
                    src={woofer}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-semibold text-lg">WOOFER</h1>
                </div>
              </div>
              <div
                onClick={handleAmplifiresForm}
                className="inline-block px-3 cursor-pointer"
              >
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    alt="category"
                    className="hover:scale-105 duration-300 ease-linear"
                    src={amplifier}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-semibold text-lg">AMPLIFIER</h1>
                </div>
              </div>
              <div
                onClick={handleScreenForm}
                className="inline-block px-3 cursor-pointer"
              >
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    alt="category"
                    className="hover:scale-105 duration-300 ease-linear"
                    src={screen}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-semibold text-lg">SCREEN</h1>
                </div>
              </div>
              <div
                onClick={handleAcousticsForm}
                className="inline-block px-3 cursor-pointer"
              >
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    alt="category"
                    className="hover:scale-105 duration-300 ease-linear"
                    src={acoustic}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-semibold text-lg">ACOUSTICS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-10">
          <h1 className="flex py-5 m-4 font-bold text-4xl text-gray-800 font-mono ">
            BEST
            <span className="text-[#A91332] ml-3">
              SELLI<span className="underline ">NG</span>
            </span>
          </h1>
        </div>
        <div className="container px-5 pb-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {productData &&
              productData.slice(0, 4).map((data, index) => (
                <div className="xl:w-1/4 md:w-1/2 p-4" key={index}>
                  <div
                    onClick={() => openProduct(data.id)}
                    className="bg-red-50 p-6 rounded-lg  hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer"
                  >
                    <img
                      className="h-40 rounded w-full object-cover object-center mb-6"
                      src={data.image}
                      alt="content"
                    />
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4 uppercase">
                      {data.brand_name} {data.item_code}
                    </h2>
                    <h3 className="tracking-widest text-indigo-500 text-base font-medium title-font leading-relaxed">
                      ₹{data.discounted_price}
                      <span className="tracking-normal text-gray-600 text-sm ml-2 line-through">
                        ₹{data.price}
                      </span>
                      <span className="ml-10 text-sm text-gray-800">
                        Discount:
                        <span className="text-green-500">{data.discount}%</span>
                      </span>
                    </h3>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div>
          <h1 className="flex py-5 m-4 font-bold text-4xl text-gray-800 font-mono ">
            BRAND
            <span className="text-[#A91332] ml-3">
              {" "}
              ASSOCIATI<span className="underline ">ON</span>
            </span>
          </h1>
          <div className="w-11/12 ml-2">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="flex items-center justify-center">
                  <img
                    src={image}
                    alt="logo"
                    className=" w-20 justify-center items-center grayscale hover:grayscale-0 cursor-pointer duration-200 ease-out"
                  ></img>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="my-10">
          <img src={dynamo} alt="banner" />
          {/* <img src={dupli} alt="banner" className="h-[600px] w-full" />
          <img src={animal} alt="animal" /> */}
        </div>
        <div className="mb-10 ">
          <h1 className="text-[#A91332] ml-3 flex py-5 m-4 font-bold text-4xl  font-mono ">
            PROJEC<span className="underline ">TS</span>
          </h1>
          <div className="w-11/12 ml-2">
            <Slider {...settings} rtl={true}>
              {clientName.map((name, index) => (
                <div key={index} className="flex items-center justify-center">
                  <h1 className="text-xl p-4 text-red-400 font-mono font-semibold hover:text-red-800 duration-300 ease-in-out ">
                    {name}
                  </h1>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* {!showProjectorForm &&
          !showWooferForm &&
          !showAmplifierForm &&
          !showSpeakerForm &&
          !showScreenForm &&
          !showAcousticsForm ? (
            <div className="mt-5">{!isActive3 && <CustomerSpeaker />}</div>
          ) : null} */}

        {/* {showSpeakerForm && <CustomerSpeaker />}
          {showProjectorForm && <CustomerProjector />}
          {showWooferForm && <CustomerWoofer />}
          {showAmplifierForm && <CustomerAmplifier />}
          {showScreenForm && <CustomerScreen />}
          {showAcousticsForm && <CustomerAcoustics />} */}
      </section>

      <Footer />
    </div>
  );
}

export default CustomerProducts;
