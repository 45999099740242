import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import banner from "../images/banner.png";
import { IoCall } from "react-icons/io5";
import { MdAlternateEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

function Contact() {
  return (
    <div>
      <Navbar />
      <div className="relative">
        <img src={banner} alt="banner" className="h-[500px] w-full " />
        <h1 className="absolute top-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 text-white text-5xl font-mono font-extrabold">
          LET'S HAVE A TALK
        </h1>
      </div>
      <section className="mt-20 mb-20">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.0321575542666!2d73.07216217770332!3d19.150069603435046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bff8098b4903%3A0x5d5c08725f03bf22!2sCasa%20Rio%20Gold%20Rd%2C%20Nilje%20Gaon%2C%20Maharashtra%20421204!5e0!3m2!1sen!2sin!4v1702375040072!5m2!1sen!2sin"
              width="100%"
              height="290"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="w-full sm:w-1/2 flex  ">
            <div className="p-2">
              <h1 className="m-4 text-2xl font-bold tracking-widest  text-[#A91332]">
                MEET US
              </h1>
              <h1 className="m-4  text-[#A91332] text-xl flex items-center">
                <IoCall />
                <span className="ml-10 text-lg text-black">
                  9022187166 / 9167543978
                </span>
              </h1>
              <h1 className="m-4  text-[#A91332] text-xl flex items-center mt-4">
                <MdAlternateEmail />
                <span className="ml-10 text-lg text-black">
                  contact.dynamo2016@gmail.com
                </span>
              </h1>
              <h1 className="m-4  text-[#A91332] text-xl flex items-center mt-4">
                <FaLocationDot />
                <span className="ml-10 text-lg text-black">
                  D 3GR Casa Rio Gold Kalyan MURIEL Shill Road Kalyan Thane
                  Maharashtra 421204
                </span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
