import React, { useState } from "react";
import loginImage from "../images/dynamo-2.gif";
import onoff from "../images/onoff.png";
import { motion } from "framer-motion";
import buffer from "../images/buffer.gif";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleButtonClick = async () => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    axios
      .post(
        "/login",
        {
          user: {
            email: email,
            password: password,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("accessToken", response.data.token);
        toast.success(response.data.message);
        navigate("/Admin");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading back to false regardless of success or error
      });
  };

  const buttonVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.5 },
    disabled: { scale: 1, cursor: "not-allowed", opacity: 0.6 },
  };
  return (
    <div className="login_bg" style={{ backgroundImage: `url(${loginImage})` }}>
      <div>
        <div className="mt-12 flex flex-col items-center  absolute inset-x-0 bottom-7">
          <h1 className="text-2xl xl:text-3xl font-extrabold">Sign In</h1>
          <div className="w-full flex-1 mt-8">
            <div className="mx-auto max-w-xs">
              <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border-2 border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border-2 border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <motion.button
                className="mt-5 font-semibold text-[#2C3333]  w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center"
                onClick={handleButtonClick}
                // disabled={isLoading}
                // variants={buttonVariants}
                whileHover={!isLoading ? "hover" : "initial"}
                initial={isLoading ? "disabled" : "initial"}
              >
                {isLoading === true ? (
                  <motion.img
                    className="w-10 h-10  "
                    src={buffer}
                    alt="On/Off"
                  />
                ) : (
                  <motion.img
                    className="w-10 h-10  "
                    src={onoff}
                    alt="On/Off"
                  />
                )}
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
