import React from "react";
import logo from "./images/WhatsApp Image 2023-12-07 at 11.22.16_0718278e.jpg";
import { Link, useNavigate } from "react-router-dom";
import Downloadpdf from "./components/Downloadpdf";

function Navbar() {
  const navigate = useNavigate();
  return (
    <div>
      <header className="text-gray-600 body-font bg-white shadow-sm">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <img
            src={logo}
            style={{ maxWidth: "10%" }}
            onClick={() => navigate("/")}
            className="cursor-pointer"
          ></img>

          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <Link to="/" className="mr-5 relative group cursor-pointer">
              <span className=" font-semibold text-[#A91332] ">HOME</span>
              <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
            </Link>
            <Link to="/Services" className="mr-5 relative group cursor-pointer">
              <span className=" font-semibold text-[#A91332] ">SERVICE</span>
              <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
            </Link>
            <Link to="/contact" className="mr-5 relative group cursor-pointer">
              <span className=" font-semibold text-[#A91332] ">CONTACT US</span>
              <span className="absolute -bottom-1 left-0 w-0 h-1 bg-[#A91332] transition-all duration-300 group-hover:w-full"></span>
            </Link>
          </nav>
          {/* <button className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
            Button
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button> */}
        </div>
      </header>
    </div>
  );
}

export default Navbar;
