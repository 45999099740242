import samsung from "../images/samsung.jpg";
import lg from "../images/lg.jpg";
import panasonic from "../images/Panasonic.jpg";
import philips from "../images/Philips.jpg";
import bose from "../images/Bose.jpg";
import jbl from "../images/jbl.jpg";
import qsc from "../images/qsc-removebg-preview.png";
import bosch from "../images/bosch.jpg";
import epson from "../images/epson.jpg";
import dell from "../images/dell.jpg";
import hp from "../images/hp-removebg-preview.png";
import honeywell from "../images/Honeywell-Logo.jpg";

const images = [
  samsung,
  lg,
  panasonic,
  philips,
  bose,
  jbl,
  qsc,
  bosch,
  epson,
  dell,
  hp,
  honeywell,
];

export default images;
